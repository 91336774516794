<template>
  <v-container fluid>
    <v-row dense>
      <v-expand-transition>
        <v-col cols="12" class="pa-12 text-center" v-if="loading">
          <div class="title">Please wait while I do some look ups...</div>
          <v-progress-circular indeterminate color="color3"></v-progress-circular>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" class="pa-12 text-center" v-if="!loading && events.length === 0">
          <div class="title">I can't find any events happening today...</div>
          <div class="subtitle-1">The link you have landed on shows all matches happeneing today on Court {{court}}.
            <br>However, we can't find any happening today.
            <br>If you feel this is an error check back in a bit or click below to find all upcoming events.</div>
          <v-btn color="color3 color3Text--text" to="/tournaments/upcoming">
            Click here to see upcoming events
          </v-btn>
        </v-col>
      </v-expand-transition>
      <v-expand-transition>
        <v-col cols="12" class="pa-12 text-center" v-if="events.length > 1">
          <div class="title">It appears there is multiple events happening today.</div>
          <div class="title grey--text">Please select the event you are looking for.</div>
            <tournament-list
              :tournaments="events"
              :loading="false"
              :ads="[]"
              :itemCountStart="-1"
              :emitter="true"
              @event-clicked="onEventClicked"
            ></tournament-list>
        </v-col>
      </v-expand-transition>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import Summary from '@/classes/TournamentSummary'
// import TournamentList from '@/components/Tournament/TournamentList'
const TournamentList = () => import('@/components/Tournament/TournamentList')

export default {
  props: ['court'],
  data () {
    return {
      events: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters(['subdomain']),
    username () {
      return this.$route.query.username || this.subdomain
    }
  },
  methods: {
    getEvents () {
      this.loading = true
      this.$VBL.organization.todaysEvents(this.username)
        .then(r => {
          r.data && this.processEvents(r.data)
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    },
    processEvents (e) {
      if (e.length === 1) {
        this.onEventClicked(e[0].id)
      } else {
        this.events = e.map(s => new Summary(s))
      }
    },
    onEventClicked (eventId) {
      this.$router.push(`/tournament/${eventId}/court/${this.court}`)
    }
  },
  mounted () {
    this.getEvents()
  },
  components: {
    TournamentList
  }
}
</script>
